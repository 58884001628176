import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';
import { brand } from 'theme/colors';
import { h2, h4 } from 'theme/typography';

export const Wrapper = styled.div`
  margin: ${space.m} auto ${space.xxl};
  padding: ${space.m} ${space.s} 0;
  max-width: ${container.xsmall};

  .preview__info {
    margin-bottom: ${space.m};

    h2 {
      ${h4}
      text-align: center;
      margin-bottom: ${space.s};
    }

    h3 {
      ${h2}
      position: relative;
      display: block;
      margin: 0 auto;
      text-align: center;
      line-height: 1;
      padding-bottom: ${space.xxs};

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 47.5%;
        width: 5%;
        height: ${toRem(4)};
        border-radius: ${space.xxs};
        background-color: ${brand.main};
      }
    }

    p {
      margin: 0;
      margin-top: ${space.m};
    }
  }

  .preview__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @media ${breakpoints.fromMobile} {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media ${breakpoints.laptop} {
    margin: ${space.xxl} auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: ${space.s};
    padding-bottom: ${space.s};
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.small};
  }
`;
