import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: ${space.xl};

  h4 {
    text-align: center;
    display: block;
    margin: 0;
    text-transform: uppercase;
  }

  .partners--head {
    flex: 1;
    display: block;
  }

  @media ${breakpoints.laptop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    h4 {
      text-align: left;
    }
  }
`;

export const Carousel = styled.div`
  flex: 3;
  overflow: hidden;

  * {
    outline: none !important;
  }

  .slick-list {
    width: 100%;
  }

  .slick-track {
    display: flex;
  }

  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
`;

export const Slide = styled.div`
  position: relative;
  padding: ${space.s};

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 160px;
    max-height: 60px;

    picture {
      img {
        object-fit: contain !important;
      }
    }
  }
`;
