import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import Partners from 'components/partners';
import Lottie from 'react-lottie';

import * as blobJson from './blob.json';

import { Button } from '../button';
import { Wrapper } from './HeroHome.styles';

const HeroHome = (props) => {
  //PROPS
  const {
    data: {
      image,
      text,
      title,
      subtitle,
      partnersLogos,
      partnersText,
      partnersTitle,
    },
  } = props;

  //PROPS

  //DEFS
  const defaultOptions = {
    loop: true,
    speed: 0.5,
    autoplay: true,
    animationData: blobJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  //DEFS

  return (
    <Wrapper>
      <span className="hero__blob">
        <Lottie options={defaultOptions} />
      </span>
      <div className="hero__content">
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <Button to="/a-empresa/sobre-nos/">Saiba mais</Button>
      </div>

      <div className="hero__image">
        <GatsbyImage
          image={image?.localFile?.childImageSharp?.gatsbyImageData}
          alt={image?.altText}
        />
      </div>

      <Partners data={{ partnersLogos, partnersText, partnersTitle }} />
    </Wrapper>
  );
};

export default HeroHome;
