import React, { useRef } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import HTMLReactParser from 'html-react-parser';
import Slider from 'react-slick';

import { Button } from 'components/button';
import { Wrapper, Carousel, Slide } from './Partners.styles';

const Partners = (props) => {
  const {
    data: { partnersLogos, partnersText, partnersTitle },
  } = props;

  //REF
  const slider = useRef(null);
  //REF

  //DEFS
  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: partnersLogos.length > 4 ? 4 : partnersLogos.length,
    swipe: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 80000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: partnersLogos.length > 3 ? 3 : partnersLogos.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: partnersLogos.length > 2 ? 2 : partnersLogos.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: partnersLogos.length > 1 ? 2 : partnersLogos.length,
        },
      },
    ],
  };
  //DEFS

  return (
    <Wrapper>
      <div className="partners--head">
        <h4>{partnersTitle}</h4>
      </div>
      <Carousel>
        <Slider {...settings} ref={slider}>
          {partnersLogos.map((item, index) => {
            const {
              partners: { partnerLogo },
            } = item;
            return (
              <Slide key={index}>
                <GatsbyImage
                  image={partnerLogo?.localFile.childImageSharp.gatsbyImageData}
                  alt={partnerLogo?.altText}
                />
              </Slide>
            );
          })}
        </Slider>
      </Carousel>
    </Wrapper>
  );
};

export default Partners;
