import styled from 'styled-components';

import { neutral, brand } from 'theme/colors';
import { breakpoints, gridSize, toRem } from 'utils/mixins';
import { container, space, fontSize } from 'theme/sizes';
import { h1, h4, h3 } from 'theme/typography';

export const Wrapper = styled.section`
  position: relative;
  padding: 0 ${space.s};
  margin: 0 auto ${space.header};
  padding-top: ${space.header};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .hero__blob {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-40%, -40%);
    z-index: 0;
  }

  .hero__image {
    width: 100%;
    position: relative;

    .gatsby-image-wrapper {
      * {
        object-fit: contain;
      }
    }
  }

  .hero__content {
    width: 100%;
    position: relative;
    margin-bottom: ${space.m};

    h1 {
      line-height: 1;
    }

    p {
    }

    a {
      display: inline-block;
    }
  }

  .hero__btn-group {
    display: flex;
    align-items: center;

    *:not(:last-child) {
      margin-right: ${space.xxs};
    }
  }

  @media ${breakpoints.laptop} {
    .hero__blob {
      transform: translate(-60%, -60%);
    }

    .hero__image {
      width: 50%;
    }

    .hero__content {
      width: 50%;
      margin-bottom: unset;
    }
  }

  @media ${breakpoints.bigDesktop} {
    max-width: ${container.small};
  }
`;
