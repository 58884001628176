import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import Team from 'components/team';
import CTA from 'components/cta';
import IconList from 'components/icon_list';
import HeroHome from 'components/hero_home';
import BlogPreview from 'components/blog-preview';

import { brand } from 'theme/colors';

const TemplateHomepage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        cta,
        homepage: {
          aboutTitle,
          aboutText,
          aboutSubtitle,
          aboutRow,
          heroImage,
          heroTitle,
          heroSubtitle,
          stafftitle,
          stafftext,
          staffsubtitle,
          staffmembers,
          partnersLogos,
          partnersText,
          partnersTitle,
          blogTitle,
          blogText,
          blogSubtitle,
          blogPosts,
        },
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main main--top">
      <HeroHome
        data={{
          image: heroImage,
          title: heroTitle,
          subtitle: heroSubtitle,
          partnersLogos,
          partnersText,
          partnersTitle,
        }}
      />
      <Hero
        data={{ title: aboutTitle, subtitle: aboutSubtitle, text: aboutText }}
        center
      />

      <div className="wrapper--breaker">
        <svg id="visual" viewBox="0 0 900 600" version="1.1">
          <path
            d="M0 495L37.5 500.8C75 506.7 150 518.3 225 480.8C300 443.3 375 356.7 450 339.8C525 323 600 376 675 382.5C750 389 825 349 862.5 329L900 309L900 601L862.5 601C825 601 750 601 675 601C600 601 525 601 450 601C375 601 300 601 225 601C150 601 75 601 37.5 601L0 601Z"
            fill={brand.fourth}
          ></path>
        </svg>
        <IconList data={aboutRow} />
        {/* <Team data={{ stafftitle, stafftext, staffsubtitle, staffmembers }} /> */}
      </div>
      <div className="wrapper--blog">
      <BlogPreview data={{ blogTitle, blogText, blogSubtitle, blogPosts }} />
      </div>
    </main>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      cta {
        ctaSubtitle
        ctaTitle
        type
        ctaCta {
          url
          title
          target
        }
        ctaImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      homepage {
        aboutTitle
        aboutText
        aboutSubtitle
        aboutRow {
          recruIcon
          recruText
          recruTitle
        }
        stafftitle
        stafftext
        staffsubtitle
        staffmembers {
          ... on WpColaborador {
            staff {
              bioThumbnail
              name
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, placeholder: BLURRED)
                  }
                }
              }
            }
            slug
          }
        }
        partnersLogos {
          ... on WpParceiro {
            partners {
              partnerLogo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 300, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        partnersText
        partnersTitle
        blogTitle
        blogText
        blogSubtitle
        blogPosts {
          ... on WpPost {
            id
            title
            slug
            date
            categories {
              nodes {
                name
                slug
              }
            }
            post {
              author {
                ... on WpColaborador {
                  title
                  slug
                }
              }
              date
              thumbText
              thumbTitle
              thumbImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      aspectRatio: 1
                    )
                  }
                }
              }
            }
          }
        }
        heroTitle
        heroSubtitle
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default TemplateHomepage;
