import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral, brand } from 'theme/colors';
import { toRem, breakpoints, gridSize } from 'utils/mixins';
import { h4 } from 'theme/typography';

export const Wrapper = styled.div`
  margin: ${space.xl} auto 0;
  max-width: ${container.xsmall};
  border-radius: ${space.xxs};

  @media ${breakpoints.laptop} {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    margin: ${space.l} auto 0;
    display: flex;
    justify-content: center;
  }
`;

export const Info = styled.div`
  width: 100%;
  padding: ${space.s};
  background-color: ${neutral.neu011};

  h4 {
    margin-bottom: ${space.m};
  }

  h3 {
    position: relative;
    font-size: ${fontSize.xl};
    margin-bottom: ${space.l};

    &::after {
      transform: ${`translateY(${space.xs})`};
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 66%;
      height: ${toRem(4)};
      border-radius: ${space.xxs};
      background-color: ${brand.main};
    }
  }

  p {
    margin-bottom: ${space.m};
  }

  a {
    display: inline;
  }

  @media ${breakpoints.fromBigMobile} {
    padding: ${space.m};
  }

  @media ${breakpoints.laptop} {
    padding: ${space.l};
    width: 40%;
  }
`;

export const Carousel = styled.div`
  width: 100%;
  background: ${neutral.neu02};
  overflow: hidden;

  * {
    outline: none !important;
  }

  .slick-list {
    width: 100%;
    overflow: visible;
  }

  @media ${breakpoints.laptop} {
    width: 60%;
    border-radius: ${space.xxs};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    flex-direction: column;

    .slick-slider,
    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-list {
      width: 80%;
    }

    .slick-slide {
      & > div {
        height: 100%;
      }
    }
  }
`;

export const Slide = styled.div`
  position: relative;
  height: 100%;

  .slide__item {
    height: 100%;
    background-color: ${neutral.neu01};
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .slide__col {
    width: 100%;

    h4 {
      margin-bottom: ${space.s};
    }

    h5 {
      font-style: italic;
    }

    &--padding {
      padding: 0 ${space.s};
      margin-top: ${space.s};

      &:last-child {
        margin-bottom: ${space.s};
      }
    }

    &--info {
      p {
        display: block;
      }

      .slide__caption {
        ${h4}
        position: relative;
        text-align: right;
        float: right;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: ${toRem(2)};
          background-color: ${brand.main};
        }
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      margin-bottom: ${space.s};
    }
  }

  @media ${breakpoints.fromBigMobile} {
    padding: ${space.m};
  }

  @media ${breakpoints.laptop} {
    padding: ${space.l};

    .slide__col {
      width: 50%;

      &--padding {
        padding: ${space.s};
        margin: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &--info {
        width: 100%;
      }

      img {
        border-radius: ${space.xxs};
        border-bottom-left-radius: 0;
        margin-bottom: unset;
      }
    }
  }
`;

export const CarouselFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${space.s};
  padding-top: 0;
  flex-wrap: wrap;

  .carousel__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: ${space.s};
  }

  @media ${breakpoints.fromBigMobile} {
    padding: ${space.m};
    padding-top: 0;
  }

  @media ${breakpoints.phablet} {
    justify-content: space-between;
  }

  @media ${breakpoints.laptop} {
    padding: ${space.l};
    padding-top: 0;
  }
`;

export const DotsWrapper = styled.ul`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    position: relative;
    width: ${space.s};
    height: ${space.xxs};
    border-radius: ${space.xxs};
    background-color: ${`${neutral.neu01}`};
    overflow: hidden;
    margin: ${space.xxs};
    overflow: hidden;
  }

  button {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }

  span {
    position: absolute;
    background-color: ${brand.main};
    left: 0;
    top: 0;
    pointer-events: none;
    border-radius: ${space.xxs};
    width: ${space.s};
    height: ${space.xxs};
    transform: translateX(-100%);
    transition: 2s;

    &.active {
      transform: translateX(0);
      transition: 8.2s;
    }
  }
`;
