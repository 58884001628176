import React from 'react';

import HTMLReactParser from 'html-react-parser';

import { Article } from 'components/blog';

import { Wrapper } from './BlogPreview.styles';

import useStateWithLocalStorage from 'utils/localStorage';

const BlogPreview = (props) => {
  const {
    data: { blogTitle, blogText, blogSubtitle, blogPosts },
  } = props;


  const [value, setValue] = useStateWithLocalStorage('bookmarked');

  return (
    <Wrapper>
      <div className="preview__info">
        <h2>{blogTitle}</h2>
        <h3>{blogSubtitle}</h3>
        {blogText && HTMLReactParser(blogText)}
      </div>
      <div className="preview__list">
        {blogPosts.map((article, index) => {
          return (
            <Article
              key={index}
              data={{ article: { node: { ...article } }, index }}
              value={value}
              setValue={setValue}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default BlogPreview;
